import React from 'react';
import Home from '../Pages/HomePage/Home';

export default function UserQuestions() { 

  return (
    <>
    <Home />
    <div>UserQuestions</div>
    </>
  )
}
