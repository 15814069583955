import React from "react"; 
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap';
import { FaListUl } from "react-icons/fa6";
import { GiPriceTag } from "react-icons/gi"; 
import { GoGift } from "react-icons/go";
import { TbHandLoveYou } from "react-icons/tb";
import './Header.css';
import { auto } from "@popperjs/core";

const Header = () => { 
    return( 
        <section id="hero-section" className="hero-section text-center">
            <section id="mini-navbar" className="mini-navbar"> 
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} md={10} lg={8}>
                            <ListGroup horizontal className="justify-content-center align-items-center">
                                <ListGroup.Item action variant="light" className="header-listgroup-item" href="/popular">
                                    <TbHandLoveYou />Shop Popular
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light" className="header-listgroup-item" href="/categories">
                                    <FaListUl style={{marginRight: '1.2px'}} />Shop Categories
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light" className="header-listgroup-item" href="/gift-ideas">
                                    <GoGift style={{marginRight: '-.55px'}} />Gift Ideas
                                </ListGroup.Item>
                                <ListGroup.Item action variant="light" className="header-listgroup-item" href="/on-sale">
                                    <GiPriceTag />On Sale
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container fluid className="header-container">
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6} className="text-center">
                        <Container className="blck-bckgrnd mb-4">           
                        <h1 className="company-vision">Creating innovative and professional products one byte at a time</h1>
                        </Container> 
                        <Button variant="outline-info" href="/categories" className="explore-shop-btn mt-4">
                            <p className="explore-shop-btn-text">Click Here to Explore</p>
                        </Button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
} 

export default Header;
