import React, { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Home from '../Pages/HomePage/Home';
import ContentIdeas from "./ContentIdeas";
import Generator from "./Generator"; 

function VideoGeneratorHome() { 
  const Web_Domain = process.env.REACT_APP_API_BASE_URL;
  const [ideas, setIdeas] = useState([]);
  const [isInputFocused, setInputFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAllIdeas, setShowAllIdeas] = useState(false);
  const [trend, setTrend] = useState(false);
  const [userIdea, setUserIdea] = useState("");
  const [finalIdea, setFinalIdea] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const location = useLocation();
  let Navigate = useNavigate(); 


  const fetchTrendingIdeas = async () => {
    //Pop up Modal
    setLoading(true); // Start loading
    setTrend(true);
    console.log("Fetching trending ideas...");

    try {
      // Step 1: Trigger Python script execution
      const triggerResponse = await fetch(
        `'${Web_Domain}/generate-trending'`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!triggerResponse.ok) {
        throw new Error(`HTTP error! status: ${triggerResponse.status}`);
      } else console.log("Fetch complete");

      console.log("Script execution started...");
      // The script is now running in the background. You can notify the user or handle UI changes accordingly.
      // Now, let's poll the server for the result.
      const fetchData = async () => {
        try {
          const dataResponse = await fetch("/data");
          if (!dataResponse.ok) {
            // If the data isn't ready, we'll wait a bit then try again
            setTimeout(fetchData, 20000); // Retry after 2 seconds
            return;
          }
          const data = await dataResponse.json(); // Assuming the server returns JSON
          setIdeas(data); // Update your state with the fetched ideas
          setLoading(false); // Stop loading
          console.log("Script execution finished");
        } catch (error) {
          console.error("Failed to fetch data:", error);
          setLoading(false); // Ensure loading is stopped if there's an error
        }
      };

      fetchData(); // Start the polling process
    } catch (error) {
      console.error("Failed to run Python script:", error);
      setLoading(false); // Ensure loading is stopped if there's an error
    }
  };

  // Need to do more with this function
  // This function needs to ask the user a series of questions so that it can get more informaion and fine details on what the user is looking for (tone, style, etc)
  const fetchIdeaQuestions = async () => {
    console.log("Idea Questions Btn works!");
    console.log("User Idea: " + userIdea);
    setUserIdea(userIdea); 
    setUserIdea(''); 
    // route user to the questions 
    Navigate('/user-idea-questions');
  };

  // Need to do more with this function
  // This function needs to then send the data to the respective python script (to be created...)
  const submitFinalIdea = async () => {
    if (!finalIdea || !finalIdea.number) {
      console.log("No idea selected.");
      return;
    }

    console.log("Idea to be submitted:", finalIdea);

    // Calculate the actual index in the full list
    const actualIndex =
      (finalIdea.currentPage - 1) * itemsPerPage + (finalIdea.number - 1);

    if (actualIndex >= 0 && actualIndex < ideas.length) {
      const selectedIdea = ideas[actualIndex];
      console.log("Submitting idea details:", selectedIdea);
      // Further processing here...
    } else {
      console.log("Selected idea is out of range.");
    } 
    setFinalIdea('');
  };

  const cancelLoad = () => { 
    console.log("Cancel btn works...");
    window.location.reload();
  };

  // Displaying a loading message or the ideas based on the current state
  return (
    <>
    <Home />
      <div className="video-generator-home">
      <Container className="glass-container">
        <Row>
        {!loading && ideas.length === 0 && (
          <>
            <h1>Welcome to the Video Idea Generator!</h1>
            <h4>Enter a prompt or select an idea from trending videos</h4>
          </>
        )}
          <Row>
          <Generator
          userIdea={userIdea}
          trend={trend}
          setInputFocused={setInputFocused}
          isInputFocused={isInputFocused}
          setUserIdea={setUserIdea}
          loading={loading}
          fetchIdeaQuestions={fetchIdeaQuestions}
          fetchTrendingIdeas={fetchTrendingIdeas}
        />
        <ContentIdeas
          ideas={ideas}
          setIdeas={setIdeas}
          finalIdea={finalIdea}
          setFinalIdea={setFinalIdea}
          submitFinalIdea={submitFinalIdea}
          loading={loading}
          cancelLoad={cancelLoad}
          showAllIdeas={showAllIdeas}
          setShowAllIdeas={setShowAllIdeas}
          fetchTrendingIdeas={fetchTrendingIdeas}
          itemsPerPage={itemsPerPage} // Passing it as a prop
        />
          </Row>
        </Row>
      </Container> 
      </div>
    </>
  );
}

export default VideoGeneratorHome;
