import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer text-center">
      <Container fluid>
        <Row>
          <Col>
            {/* Additional footer content can go here */}
          </Col>
          <Col>
            {/* Additional footer content can go here */}
          </Col>
        </Row>
        <Row>
          <Col>
            <p>&copy; 2024 Byte-Sized Tech. All rights reserved.</p>
            <div>
              <a href="https://www.youtube.com/@byte-sizedtech24" target="_blank" rel="noopener noreferrer">YouTube</a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
