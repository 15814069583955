import React from "react";
import { useNavigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';

function Generator({userIdea, trend, isInputFocused, setUserIdea, setInputFocused, fetchIdeaQuestions, fetchTrendingIdeas, loading}) {
  let Navigate = useNavigate();

  if (trend) {
    return null; // or you could return an alternative component/view here
  }

  return (
    <Container className="video-generator-home">
      <Row className="justify-content-md-center">
        <Col>
          <Button
            variant={loading || userIdea.trim().length > 0 ? "secondary" : "danger"}
            onClick={fetchTrendingIdeas}
            disabled={loading || userIdea.trim().length > 0}
          >
            {loading ? "Generating..." : "Generate from Trending Topics"}
          </Button>
        </Col>
        </Row>
        <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Enter Your Own Idea"
            value={userIdea}
            onChange={(e) => setUserIdea(e.target.value)}
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            onKeyDown={(e) => { if (e.key === 'Enter') { fetchIdeaQuestions(); setUserIdea(''); Navigate('/user-idea-questions') }}}
          />
          <Row> 
          <Col>
          <Button
            variant={userIdea.trim().length === 0 ? "secondary" : "danger"}
            onClick={fetchIdeaQuestions}
            disabled={loading || userIdea.trim().length === 0}
          >
            Generate from Your Idea
          </Button>
        </Col>
        </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Generator;
