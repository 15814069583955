import React from 'react';
import { Container, Navbar, Nav, Form, Button } from 'react-bootstrap';
import { CgProfile } from "react-icons/cg";
import logo from "../../BSTlogo2.png";

const Navigationbar = () => {
  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="navbar nb-bg">
          <Navbar.Brand href="/" style={{color: 'white'}}>
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="Byte-Sized Tech logo"
            />
            Byte-Sized Tech
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" style={{color: "white"}} />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="nav-spacing">
            <Nav.Link className='nav-link me-auto'><CgProfile className='profile-pic'/></Nav.Link>
              <Nav.Link href="/" className='nav-link'>Home</Nav.Link>
              <Nav.Link href="/shop" className='nav-link'>Shop</Nav.Link>
              <Nav.Link href="/youtube" className='nav-link'>YouTube Channel</Nav.Link>
              <Nav.Link href="/contact" className='nav-link'>Contact</Nav.Link>
              <Nav.Link href="/testimonials" className='nav-link'>Testimonials</Nav.Link>
            </Nav> 
            <Form className="d-flex">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                />
              <Button variant="outline-info">Search</Button>
            </Form>
          </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default Navigationbar;
