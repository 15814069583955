import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './Shop.css';
import ShopManagement from './ShopManagement/ShopManagement';

const Shop = () => {
    return (
        <ShopManagement renderShop={({
          categories,
          products,
          goToCategory,
          goToProduct,
          handleAddCategory,
          handleAddProduct,
          handleDeleteCategory,
          handleDeleteProduct,
          handleUpdateCategory,
          handleUpdateProduct,
            refreshCategories,
            refreshProducts,
        }) => (
            <Container fluid className="shop-page">
                <div>
                    <Row>
                        <Col>
                            {/* The ShopManagement logic is passed down to Shop components */}
                            <h2>Manage Products and Categories</h2>
                        </Col>
                    </Row>
                </div>

                {/* Product Categories */}
                <Row className="mt-4">
                    {categories.map((category) => (
                        <Col xs={6} md={4} lg={3} key={category.id}>
                            <Card className="category-card" onClick={() => goToCategory(category.name)}>
                                <Card.Img variant="top" src={category.imgSrc} alt={category.name} />
                                <Card.Body>
                                    <Card.Title>{category.name}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>

                {/* Product Grid */}
                <Row className="mt-4">
                    {products.map((product) => (
                        <Col xs={12} md={6} lg={4} xl={3} key={product.id}>
                            <Card className="product-card"  onClick={() => goToProduct(product.id)}>
                                <Card.Img variant="top" src={product.image} alt={product.name} />
                                <Card.Body>
                                    <Card.Title>{product.name}</Card.Title>
                                    <Card.Text>${product.price}</Card.Text>
                                    <Button variant="primary">Add to Cart</Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        )} />
    );
};

export default Shop;
