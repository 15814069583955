import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router';


const ShopCategories = () => {
    const Web_Domain = 'https://byte-sizedtech.net';
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState({ name: '', imgSrc: '', description: '' });
    const [error, setError] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCategories(); 
    }, []);

    const checkAdminStatus = () => {
        // Mock admin status check. In a real app, this would be determined by authentication logic.
        const user = { isAdmin: true }; // Replace with actual user authentication logic
        setIsAdmin(user.isAdmin);       
    };
    
    const fetchCategories = async () => {
        try {
            console.log(Web_Domain);
            const response = await axios.get('https://byte-sizedtech.net/routes/api/categories');
            if (Array.isArray(response.data)) {
                setCategories(response.data);
            } else {
                console.error('Unexpected data format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    }; 

    const goToCategory = (name) => { 
        navigate(`/product/categories/${name}`);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCategory({ ...newCategory, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newCategory.name || !newCategory.imgSrc || !newCategory.description) {
            setError('All fields are required');
            return;
        }
        try {
            await axios.post('/routes/api/categories', newCategory);
            fetchCategories();
            setNewCategory({ name: '', imgSrc: '', description: '' });
            setError('');
        } catch (error) {
            console.error('Error adding category:', error);
            setError('Error adding category');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/routes/api/categories/${id}`);
            fetchCategories();
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    return ( 
        <>
        <Container>
            <h1 className="text-center my-5">Shop Categories</h1>
            {isAdmin && (
                <>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit} className="mb-5">
                        <Form.Group controlId="formCategoryName">
                            <Form.Label>Category Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newCategory.name}
                                onChange={handleChange}
                                placeholder="Enter category name"
                            />
                        </Form.Group>
                        <Form.Group controlId="formCategoryImage">
                            <Form.Label>Image URL</Form.Label>
                            <Form.Control
                                type="text"
                                name="imgSrc"
                                value={newCategory.imgSrc}
                                onChange={handleChange}
                                placeholder="Enter image URL"
                            />
                        </Form.Group>
                        <Form.Group controlId="formCategoryDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                name="description"
                                value={newCategory.description}
                                onChange={handleChange}
                                placeholder="Enter description"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={!newCategory.name || !newCategory.imgSrc || !newCategory.description}>
                            Add Category
                        </Button>  
                    </Form>
                </>
            )}
            {!isAdmin && (
            <div>
                    <Button variant="primary" onClick={() => checkAdminStatus()}>
                            Login
                        </Button>
            </div> 
            )} 
            
        </Container> 
        <Container> 
            <Row>
                {Array.isArray(categories) && categories.map((category, index) => (
                    <Col key={index} sm={12} md={6} lg={4} className="mb-4">
                        <Card onClick={() => goToCategory(category.name)}> {/* Pass category Name to goToCategory */}
                            <Card.Img variant="top" src={category.imgSrc} alt={category.name} />
                            <Card.Body>
                                <Card.Title>{category.name}</Card.Title>
                                <Card.Text>{category.description}</Card.Text>
                                {isAdmin && (
                                    <Button variant="danger" onClick={(e) => { 
                                        e.stopPropagation();  // Prevents triggering goToCategory when deleting
                                        handleDelete(category.id);
                                    }}>Delete</Button>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container> 
        </>
    );
};

export default ShopCategories;
