import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Container, Row, Col, Card } from 'react-bootstrap';

const CategoryPage = () => {
    const { name } = useParams(); // Get the category name from the URL
    const [products, setProducts] = useState([]); // Initialize products as an empty array
    const [error, setError] = useState(''); // State for handling errors

    // Function to fetch products by category name
    const fetchProducts = async () => {
        try {
            const response = await axios.get(`/routes/api/products/category/${encodeURIComponent(name)}`);
            console.log('API Response:', response.data); // Debug: Check what the API is returning

            if (Array.isArray(response.data)) {
                setProducts(response.data); // Set products if the response is an array
            } else {
                console.error('Expected an array but got:', response.data);
                setProducts([]); // Set an empty array if the response is not as expected
            }
        } catch (error) {
            console.error('Error fetching products:', error);
            setError('Failed to load products for this category');
        }
    };

    // Fetch products when the component mounts or when the category name changes
    useEffect(() => {
        fetchProducts();
    }, [name]);

    return (
        <Container>
            <h1 className="my-4">Products in "{name}"</h1>

            {error && <div className="alert alert-danger">{error}</div>}
            {products.length === 0 && !error && <div>No products found for this category.</div>}

            <Row>
                {products.map(product => (
                    <Col xs={12} md={6} lg={4} key={product.id} className="mb-4">
                        <Card>
                            <Card.Img variant="top" src={product.image} alt={product.name} />
                            <Card.Body>
                                <Card.Title>{product.name}</Card.Title>
                                <Card.Text>${product.price}</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CategoryPage;
