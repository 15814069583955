import React, { useState } from 'react';
import { Container, Row, Col, Table, Button, Form, Pagination } from 'react-bootstrap';


function ContentIdeas({ ideas, setIdeas, loading, cancelLoad, finalIdea, fetchTrendingIdeas, submitFinalIdea, setFinalIdea, itemsPerPage }) {
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // Calculate the total number of pages
  const totalPages = Math.ceil(ideas.length / itemsPerPage); 

  // Slice the items to display on the current page
  const currentItems = ideas.slice(indexOfFirstItem, indexOfLastItem);

  // Change page handler
  const paginate = (pageNumber) => setCurrentPage(pageNumber); 

  let items = [];
  for (let number = 1; number <= totalPages; number++) {
    items.push(
      <Pagination.Item key={number} active={number === currentPage} onClick={() => paginate(number)}>
        {number}
      </Pagination.Item>,
    );
  }

  

  const refreshIdeas = () => { 
    setIdeas('');
    fetchTrendingIdeas();
  }

  const handleFinalIdeaChange = (e) => {
    const number = e.target.value; // Assuming this is a valid number
    setFinalIdea({ number: parseInt(number, 10), currentPage });
  };
   

    if (loading) return (
      <>

      <div className="spinner-container">
      <button className='cancel-btn' onClick={cancelLoad}>Cancel</button>
      <h1>Generating content ideas...</h1>
      <div className="spinner" >
      </div>
    </div>
    </>
      ) 
    return (
      <>
      {!loading && ideas.length > 0 && (
    <Container>
        <Row>
          <Col>
            <Form.Label><h3>Please enter the number associated with the idea you would like to use</h3></Form.Label>
            <Form.Control type="number" placeholder="Enter Number.." onChange={handleFinalIdeaChange} />
            <Button variant="primary" onClick={submitFinalIdea} className="m-2">Submit Idea</Button>
            <Button variant="secondary" onClick={refreshIdeas}>Refresh Ideas</Button>
          </Col>
        </Row>

      <Row>
        <Col>
          <Table responsive striped bordered hover variant="dark" >
            <thead>
              <tr>
                <th className='th-theme'>#</th>
                <th className='th-theme'>Idea</th>
                <th className='th-theme'>Link</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  <td>{indexOfFirstItem + index + 1}</td>
                  <td>{item.idea}</td>
                  <td><a href={item.link} style={{color: 'white'}}>View</a></td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="justify-content-center" style={{color: 'white'}}>
            <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1}>Prev</Pagination.Prev>
            <span style={{ margin: '0 15px' }}>Page {currentPage} of {totalPages}</span>
            <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === totalPages}>Next</Pagination.Next>
          </Pagination>
          <Button variant="danger" onClick={cancelLoad}>Cancel</Button>
        </Col>
      </Row>
    </Container>
        )} 
    </>
    );
}

export default ContentIdeas;
