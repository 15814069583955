import React from 'react'; 
import ContactUs from '../ContactPage/ContactUs';
import Header from "../Header/Header";
import LatestVideos from '../LatestVideosPage/LatestVideos';
import ShopSection from "../ShopPage/ShopSection";
import Testimonials from "../TestimonialsPage/TestimonialPage"; 
import 'bootstrap/dist/css/bootstrap.min.css';

const Home = () => {
  return (
    <>
      {/* Header */}
      <Header /> 

      {/* Shop Section */}
      <ShopSection />

      {/* Latest Videos Section */}
      <LatestVideos />

      {/* Testimonials Section */}
      <Testimonials />

      {/* Contact Section */}
      <ContactUs />
    </>
  );
}

export default Home;
