import { lib, enc, SHA256 } from 'crypto-js';

const base64UrlEncode = (value) => {
  return enc.Base64.stringify(value)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

export const generatePkceChallenge = () => {
  const codeVerifier = base64UrlEncode(lib.WordArray.random(32));
  const hash = SHA256(enc.Utf8.parse(codeVerifier));
  const codeChallenge = base64UrlEncode(hash);
  return { codeVerifier, codeChallenge };
};
