import React from "react"; 
import { Container, Row, Col, Card, Button } from 'react-bootstrap'; 

const ShopSection = () => { 
    return( 
        <section id="shop-section" className="shop-section">
        <Container fluid>
          <Row>
            <Col md={6}>
              <Card className="mb-4 shop-section-card">
                <Card.Body>
                  <Card.Title>3D Printing & Custom Designs</Card.Title>
                  <Card.Text>
                    Transform your ideas into reality with our state-of-the-art 3D printing services. From prototypes to personalized gifts, we bring your visions to life with precision and quality. Explore our wide range of unique, customizable designs tailored to your needs.
                  </Card.Text>
                  <Button variant="outline-info" href="/shop">Discover More</Button>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6}>
              <Card className="mb-4 shop-section-card">
                <Card.Body>
                  <Card.Title>Innovative Coding Solutions</Card.Title>
                  <Card.Text>
                    Dive into the world of technology with our expert coding and programming services. Whether you're looking for custom software development, automation, or web solutions, we've got the skills to turn your ideas into robust applications. Let's build something amazing together.
                  </Card.Text>
                  <Button variant="outline-info" href="/coding-services">Explore Services</Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    )
}

export default ShopSection;
