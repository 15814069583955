/* eslint-disable react/prefer-stateless-function */
import React, { Component } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AutomatedVideoMaker from "./Components/Automatic_Video_Generator/AutomatedVideoMaker"; 
import ContactUs from "./Components/Pages/ContactPage/ContactUs";
import Error404 from "./Components/Pages/Error404"; 
import Footer from "./Components/Footer/Footer";
import GiftIdeas from "./Components/Pages/ShopPage/GiftIdeas";
import Home from "./Components/Pages/HomePage/Home";
import Navbar from "./Components/Navbar/Navbar";
import OnSale from "./Components/Pages/ShopPage/OnSale";
import CategoryPage from "./Components/Pages/ShopPage/ShopCategories/CategoryPage";
import ProductPage from "./Components/Pages/ShopPage/ProductPage";
import Shop from "./Components/Pages/ShopPage/Shop";
import ShopCategories from "./Components/Pages/ShopPage/ShopCategories/ShopCategories";
import ShopPopular from "./Components/Pages/ShopPage/ShopPopular";
import Testimonials from "./Components/Pages/TestimonialsPage/TestimonialPage";
import Users from "./Components/Users/Users";
import UserQuestions from "./Components/Video_Generator/UserQuestions";
import VideoGenerator from "./Components/Video_Generator/VideoGeneratorHome";
import "./App.css";
import "./Components/Navbar/Navbar.css"
import "./Components/Pages/HomePage/HomeStyles.css";
import "./Components/Video_Generator/VideoGeneratorStyles.css";

class App extends Component {
  render() {
    return ( 
      <div className="app">
      <Router>
        <Navbar />
        <div className="content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/automated-video-generator" element={<AutomatedVideoMaker />} />
          <Route path="/categories" element={<ShopCategories />} />
          <Route path="/contact" element={<ContactUs />} /> 
          <Route path="/gift-ideas" element={<GiftIdeas />} />
          <Route path="/on-sale" element={<OnSale />} />
          <Route path="/popular" element={<ShopPopular />} />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/product/categories/:name" element={<CategoryPage />} />
          <Route path="/shop" element={<Shop />} /> 
          <Route path="/testimonials" element={<Testimonials />} /> 
          <Route path="/users" element={<Users />} />
          <Route path="/user-idea-questions" element={<UserQuestions />} />
          <Route path="/video-generator" element={<VideoGenerator />} />
          <Route path="/*" element={<Error404 />} />
        </Routes> 
        </div>
        <Footer />
      </Router>
      </div>
    );
  }
}

export default App;
