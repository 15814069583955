import React from "react"; 
import { Container, Row, Col, Navbar, Nav, Button, Card, Form } from 'react-bootstrap'; 


const LatestVideos = () => { 
    return( 
        <section id="youtube" className="youtube-section text-center">
        <Container fluid>
          <h2>Latest Videos</h2>
          <Row>
            <Col>
              <iframe width="100%" height="350" src="https://www.youtube.com/embed/latest-video-id" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            </Col>
          </Row>
        </Container>
      </section>
    )
} 

export default LatestVideos;