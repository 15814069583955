import React from 'react';
import { generatePkceChallenge } from '../../Utils/oauth.js';

const AutomatedVideoMaker = () => {
  const handleLogin = () => {
    const { codeVerifier, codeChallenge } = generatePkceChallenge();
    console.log('Code Verifier:', codeVerifier); // Save this securely!
    console.log('Code Challenge:', codeChallenge);

    // Construct the authorization URL with the code challenge and other required parameters
    const clientId = 'YOUR_CANVA_CLIENT_ID';
    const redirectUri = encodeURIComponent('YOUR_REDIRECT_URI');
    const scope = encodeURIComponent('YOUR_REQUESTED_SCOPE');
    const authUrl = `https://www.canva.com/api/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&code_challenge=${codeChallenge}&code_challenge_method=S256`;

    // Redirect the user to the authorization URL
    window.location.href = authUrl;
  };

  return <button onClick={handleLogin}>Login with Canva</button>;
};

export default AutomatedVideoMaker;
