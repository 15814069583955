import { right } from "@popperjs/core";
import React from "react"; 
import { Container, Row, Col, Navbar, Nav, Button, Card, Form } from 'react-bootstrap'; 


const ContactUs = () => { 
    return ( 

<section id="contact" className="contact-section">
        <Container fluid>
          <h2>Contact Us</h2>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control className="contact-us-form" type="text" placeholder="Enter your name" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control className="contact-us-form" type="email" placeholder="Enter your email" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control className="contact-us-form" as="textarea" placeholder="Enter your message" rows={3} />
            </Form.Group>
            <div className="d-flex justify-content-end">
            <Button variant="outline-info" type="submit" style={{margin: "5px"}}>Submit</Button>
          </div>
          </Form>
        </Container>
      </section> 
    )
} 

export default ContactUs;