import React from "react"; 
import { Container, Row, Col, Navbar, Nav, Button, Card, Form } from 'react-bootstrap'; 

const ShopPopular = () => { 
    return( 
        <section id="shop-popular" className="shop-popular">
        <Container>
          <h1>Hello world!</h1>
          <h2>Welcome to the Popular shop!</h2>
        </Container>
      </section>
    )
}

export default ShopPopular; 