import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState({ name: '', email: '', password: '', role: '0' });
    const [error, setError] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        checkAdminStatus();
        fetchUsers();
    }, []);

    const checkAdminStatus = () => {
        // Mock admin status check. In a real app, this would be determined by authentication logic.
        const user = { isAdmin: true }; // Replace with actual user authentication logic
        setIsAdmin(user.isAdmin);
    };

    const fetchUsers = async () => {
        try {
            const response = await axios.get('/routes/api/users');
            if (Array.isArray(response.data)) {
                setUsers(response.data);
            } else {
                console.error('Unexpected data format:', response.data);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewUser({ ...newUser, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!newUser.name || !newUser.email || !newUser.password || !newUser.role) {
            setError('All fields are required');
            return;
        }
        try {
            await axios.post('/routes/api/users', newUser);
            fetchUsers();
            setNewUser({ name: '', email: '', password: '', role: '' });
            setError('');
        } catch (error) {
            console.error('Error adding user:', error);
            setError('Error adding user');
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/routes/api/users/${id}`);
            fetchUsers();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    return (
        <Container>
            <h1 className="text-center my-5">Display Users</h1>
            {isAdmin && (
                <>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit} className="mb-5">
                        <Form.Group controlId="formUserName">
                            <Form.Label>User Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={newUser.name}
                                onChange={handleChange}
                                placeholder="Enter user name"
                            />
                        </Form.Group>
                        <Form.Group controlId="formUserEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                value={newUser.email}
                                onChange={handleChange}
                                placeholder="Enter email"
                            />
                        </Form.Group>
                        <Form.Group controlId="formUserPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                value={newUser.password}
                                onChange={handleChange}
                                placeholder="Enter password"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" disabled={!newUser.name || !newUser.email || !newUser.password || !newUser.role}>
                            Add User
                        </Button>
                    </Form>
                </>
            )}
            <Row>
                {Array.isArray(users) && users.map((user, index) => (
                    <Col key={index} sm={12} md={6} lg={4} className="mb-4">
                        <Card>
                            <Card.Body>
                                <Card.Title>{user.name}</Card.Title>
                                <Card.Text>Email: {user.email}</Card.Text>
                                <Card.Text>Role: {user.role}</Card.Text>
                                <Button variant="danger" onClick={() => handleDelete(user.id)}>Delete</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default UsersPage;
