import React, { useState, useEffect } from 'react';
import axios from 'axios'; 
import { useNavigate } from 'react-router';

const ShopManagement = ({ renderShop }) => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    const refreshProducts = () => {
        axios.get('/routes/api/products')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setProducts(response.data);
                } else {
                    console.error('API response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the products!', error);
            });
    };

    const refreshCategories = () => {
        axios.get('/routes/api/categories')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setCategories(response.data);
                } else {
                    console.error('API response is not an array:', response.data);
                }
            })
            .catch(error => {
                console.error('There was an error fetching the categories!', error);
            });
    }; 

    const goToCategory = (name) => { 
        navigate(`/product/categories/${encodeURIComponent(name)}`);
    };  

    const goToProduct = (id) => { 
        navigate(`/product/${id}`);
    }

    const handleAddProduct = (product) => {
        axios.post('/routes/api/products/add', product)
            .then(() => refreshProducts())
            .catch(error => console.error('Failed to add product', error));
    };

    const handleUpdateProduct = (id, product) => {
        axios.put(`/routes/api/products/update/${id}`, product)
            .then(() => refreshProducts())
            .catch(error => console.error('Failed to update product', error));
    };

    const handleDeleteProduct = (id) => {
        axios.delete(`/routes/api/products/delete/${id}`)
            .then(() => refreshProducts())
            .catch(error => console.error('Failed to delete product', error));
    };

    const handleAddCategory = (category) => {
        axios.post('/routes/api/categories', category)
            .then(() => refreshCategories())
            .catch(error => console.error('Failed to add category', error));
    };

    const handleUpdateCategory = (id, category) => {
        axios.put(`/routes/api/categories/${id}`, category)
            .then(() => refreshCategories())
            .catch(error => console.error('Failed to update category', error));
    };

    const handleDeleteCategory = (id) => {
        axios.delete(`/routes/api/categories/${id}`)
            .then(() => refreshCategories())
            .catch(error => console.error('Failed to delete category', error));
    };

    useEffect(() => {
        refreshProducts();
        refreshCategories();
    }, []);

    return renderShop({
        products,
        categories,
        goToCategory,
        goToProduct,
        handleAddCategory,
        handleAddProduct,
        handleDeleteCategory,
        handleDeleteProduct,
        handleUpdateCategory,
        handleUpdateProduct,
        refreshCategories,
        refreshProducts,
    });
};

export default ShopManagement;
